import React, { useState } from 'react'

import { useApp } from '@/core/contexts/app'

import Anchor from '@/components/base/anchor/Anchor'
import Button from '@/components/base/button/Button'
import Image from '@/components/base/image/Image'
import Icon from '@/components/base/icon/Icon'

import { OfferProps } from './type'
import { classnames } from 'begonya/functions'

const Offer: React.FunctionComponent<OfferProps> = ({
  title,
  subTitle,
  discount,
  anchor,
  icon,
}) => {
  const app = useApp()
  const [offerStatus, setOfferStatus] = useState(false)

  const handleClose = () => {
    setOfferStatus(false)
  }

  const handleClick = () => {
    setOfferStatus(!offerStatus)
  }

  return (
    <>
      <div className={classnames('offer', offerStatus && 'offer--active')}>
        <Button onClick={handleClose} className="close">
          <Icon name="icon-clear" />
        </Button>
        <div className="title">
          {title}
          <span>{subTitle}</span>
        </div>
        <div className="discount">
          <div className="count">{discount}</div>
          <span>{app.settings.translations['discount']}</span>
        </div>
        {/* <Anchor {...anchor} className="btn">
          {app.settings.translations['register']}
        </Anchor> */}
      </div>
      <Button onClick={handleClick} className="contact-offer">
        <Image {...icon} alt="" />
      </Button>
    </>
  )
}

export default Offer
